/* global GLightbox */
/* global WOW */
/* global JQuery */
import React, { useEffect, useState } from "react";
import hero_img from "../../assets/img/all_usecase.png";
import logo_image from "../../assets/img/logo.png";
// import "../../assets2/css/bootstrap.min.css";
import "../../assets2/css/style.css";
// import "../../assets2/lib/owlcarousel/assets/owl.carousel.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import "../../assets2/lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css";
import Footer from "../../footer.jsx";

export default function Dashboard() {
  const [display, setDisplay] = useState("none");
  const [userName, setUseName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [amount, setAmount] = useState(0);
  const [payment_method, setPayment_method] = useState(0);
  const [totalToken, setTotalToken] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [referral, setReferral] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");

  // set countdown
  const [cdays, setCdays] = useState("");
  const [chours, setChours] = useState("");
  const [cminutes, setCminutes] = useState("");
  const [cseconds, setSeconds] = useState("");

  // set Referral State

  useEffect(() => {
    if (!Constants.getCookies("token")) {
      Constants.redirectPage("/signin");
      return false;
    }
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
    getUserdata();
    getTokenomics();

    // Set the date we're counting down to
    var countDownDate = new Date("Nov 20, 2023 12:00:00").getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      setCdays(days);
      setChours(hours);
      setCminutes(minutes);
      setSeconds(seconds);
      // // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        setCdays(0);
        setChours(0);
        setCminutes(0);
        setSeconds(0);
      }
    }, 1000);
  }, []);

  const data = {
    amount: amount,
    payment_method: payment_method,
  };

  const Logout = async () => {
    Constants.logout();
  };

  const getUserdata = async () => {
    let userdata = JSON.parse(Constants.getItem("userdata"));
    setUseName(userdata.fullname);
    setPhoneNo(userdata.phone);
    setReferral(userdata.referral);
  };

  const getTokenomics = async () => {
    let Tokendetails = await ApiServices.getService("account/tokenomics");
    if (Tokendetails) {
      setTotalToken(
        Constants.numberWithCommas(Tokendetails.data.total_for_presale)
      );
      setTotalSold(Constants.numberWithCommas(Tokendetails.data.total_sold));
      let remain =
        parseFloat(Tokendetails.data.total_for_presale) -
        parseFloat(Tokendetails.data.total_sold);
      setTotalRemaining(Constants.numberWithCommas(remain));
    }
  };

  var x = setInterval(function () {
    getTokenomics();
  }, 60000);

  return (
    <>
      {/* header starts here */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>
          <div
            style={{
              color: "#fff",
              position: "absolute",
              marginLeft: "100px",
              fontSize: "20px",
            }}
          >
            <p> {userName}</p> <p style={{ marginTop: "-20px" }}>{phoneNo}</p>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li>
                <a className="nav-link active">Dashboard</a>
              </li>
              <li>
                <Link to="/buy-token" className="nav-link">
                  Buy Token
                </Link>
              </li>
              <li>
                <Link to="/transaction" className="nav-link">
                  Transactions
                </Link>
              </li>
              <li>
                <a href="/referral" className="nav-link">
                  Referrals
                </a>
              </li>
              <li>
                <Link to="/change-password" className="nav-link">
                  Change Password
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="https://afriqarbitragesystem.zendesk.com/hc/en-gb"
                  target="_blank"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Help Center
                </Link>
              </li>
              <li>
                <a
                  className="nav-link"
                  onClick={Logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* end of header */}

      {/* hero starts here */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>AAS Token Tailored Solutions for Sustainable Progress.</h1>
              <h2>
                Discover the Future of Finance with AAS Token Your Passport to a
                Decentralized World!
              </h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                {/* <a href="#about" className="btn-get-started scrollto">
                  Get Started
                </a> */}
                <a
                  target="_blank"
                  href="https://www.youtube.com/watch?v=iz9zhHggdh0"
                  className="glightbox btn-watch-video"
                >
                  <i className="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src={hero_img} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <section
        style={{
          textAlign: "center",
          alignContent: "center",
        }}
      >
        {/* add wallet button */}
        <div className="text-center" style={{ marginBottom: "20px" }}>
          <Link
            style={{
              fontStyle: "underline",
              color: "blue",
              border: "none",
            }}
            to="/add-wallet"
            className="nav-link"
          >
            <span
              style={{
                border: "0px blue solid",
                borderRadius: "25px",
                padding: "15px 30px",
                backgroundColor: "#209dd8",
                color: "#f4fafd",
              }}
            >
              Add Wallet Address{" "}
            </span>
          </Link>
        </div>

        <strong>See your Referral link below</strong>
        <p style={{ fontWeight: "600" }}>{referral}</p>
        <br />
        {/* <p style={{ marginButton: "-10px", fontWeight: "bold" }}>
          Count Down To Buy Token
        </p>
        <p style={{ fontWeight: "500", fontSize: "30px", color: "red" }}>
          {" "}
          {cdays}day {chours}hrs {cminutes}mins {cseconds} secs
        </p> */}
        {/* Total AAST left */}
        <p style={{ fontWeight: "500", fontSize: "20px", color: "blue" }}>
          {" "}
          Total Pre-Sale: {totalToken}
        </p>
        <p style={{ fontWeight: "500", fontSize: "20px", color: "red" }}>
          {" "}
          Total Sold: {totalSold}
        </p>
        <p style={{ fontWeight: "500", fontSize: "20px", color: "green" }}>
          {" "}
          Total Remaining: {totalRemaining}
        </p>
        <p>Watch the video to learn how to purchase AAS tokens.</p>
        <iframe
          title="YouTube Video"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/VRVchWbZXH0`}
          // frameBorder="0"
          allowFullScreen
        />
      </section>

      {/* footer starts */}
      <Footer />
      {/* footer ends */}

      {/* <div id="preloader"></div> */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
