import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../ApiServices.js";
import Constants from "../Constants.js";
import hero_img from "../assets/img/all_usecase.png";
import client_3 from "../assets/img/clients/c.png";
import client_4 from "../assets/img/clients/eth.png";
import client_5 from "../assets/img/clients/meta.png";
import client_6 from "../assets/img/clients/mix.png";
import client_1 from "../assets/img/clients/now.png";
import client_2 from "../assets/img/clients/teth.png";
import logo_image from "../assets/img/logo.png";
import Footer from "../footer.jsx";
import img_payment from "../img/payment-1.png";
import img_payment_2 from "../img/payment-2.png";
import Newsletter from "../newsletter.jsx";
import roadmap from "../roadmap/AAS_RoadMap1.pdf";
import whitepaper from "../whitepaper/AAS_Whitepaper.pdf";

import $ from "jquery";

export default function Index() {
  const [cdays, setCdays] = useState("");
  const [chours, setChours] = useState("");
  const [cminutes, setCminutes] = useState("");
  const [cseconds, setSeconds] = useState("");
  const [totalToken, setTotalToken] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [contractAddress, setContractAddress] = useState("");

  useEffect(() => {
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
    // for the other rendering on the page
    // import("../assets/js/main.js");

    // for the roadmap
    import("../assets/js/main2.js");

    getTokenomics();
    getTimery();
  }, []);

  // get Tokenomics
  const getTokenomics = async () => {
    let Tokendetails = await ApiServices.getService("account/tokenomics");
    if (Tokendetails) {
      setTotalToken(
        Constants.numberWithCommas(Tokendetails?.data?.total_for_presale)
      );
      setTotalSold(Constants.numberWithCommas(Tokendetails?.data?.total_sold));
      let remain =
        parseFloat(Tokendetails?.data?.total_for_presale) -
        parseFloat(Tokendetails?.data?.total_sold);
      setTotalRemaining(Constants.numberWithCommas(remain));
    }
  };

  // get Timery
  const getTimery = async () => {
    let timerdetails = await ApiServices.getService("account/tokenomics");
    if (timerdetails) {
      // Set the date we're counting down to
      var countDownDate = new Date(timerdetails.data.presale_end).getTime();

      // Update the count down every 1 second
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        setCdays(days);
        setChours(hours);
        setCminutes(minutes);
        setSeconds(seconds);
        // // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(x);
          setCdays(0);
          setChours(0);
          setCminutes(0);
          setSeconds(0);
        }
      }, 1000);
    }
  };

  var x = setInterval(function () {
    getTokenomics();
  }, 60000);

  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [subject, setSubject] = useState("");
  // const [message, setMessage] = useState("");

  return (
    <>
      {/* header starts here */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link active">
                  Home
                </Link>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About
                </a>
              </li>
              <li>
                <Link to="/usecase" className="nav-link">
                  Usecase
                </Link>
              </li>
              <li>
                <a className="nav-link" href={whitepaper} target="_blank">
                  WhitePaper
                </a>
              </li>
              <li>
                <a className="nav-link" href={roadmap} target="_blank">
                  Roadmap
                </a>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="https://afriqarbitragesystem.zendesk.com/hc/en-gb"
                  target="_blank"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Help Center
                </Link>
              </li>
              {/* <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact
                </a>
              </li> */}
              {/* <li>
                <a className="nav-link scrollto" href="#faq">
                  FAQ
                </a>
              </li> */}
              <li>
                <Link to="/signin" className="getstarted">
                  Login
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* header end here */}
      {/* hero starts here */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>AAS Token Tailored Solutions for Sustainable Progress.</h1>
              <h2>
                Discover the Future of Finance with AAS Token Your Passport to a
                Decentralized World!
              </h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <Link to="/signup" className="btn-get-started scrollto">
                  Get Started
                </Link>
                <a
                  href="https://www.youtube.com/watch?v=iz9zhHggdh0"
                  className="glightbox btn-watch-video"
                >
                  <i className="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src={hero_img} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}
      {/* main starts */}
      <main id="main">
        <section id="cliens" className="cliens section-bg">
          <div className="container">
            <div className="row" data-aos="zoom-in">
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img src={client_1} className="img-fluid" alt="" />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img src={client_2} className="img-fluid" alt="" />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img src={client_3} className="img-fluid" alt="" />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img src={client_4} className="img-fluid" alt="" />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img src={client_5} className="img-fluid" alt="" />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img src={client_6} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Cliens Section --> */}

        {/* <!-- ======= About Us Section ======= --> */}
        <section id="#" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row content">
              <div className="container-xxl bg-light py-5 my-5">
                <div className="container py-5">
                  <div
                    className="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                    style={{ maxWidth: "500px" }}
                  >
                    <a
                      target="_blank"
                      href="https://www.youtube.com/watch?v=R4IKsl2CQ4Q"
                      className="glightbox btn-watch-video"
                    >
                      <i className="bi bi-play-circle"></i>{" "}
                      <span>Guide to add Wallet Address</span>
                    </a>

                    {/* AAST CONTRACT ADDRESS */}
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "20px",
                        color: "#3c4c6a",
                      }}
                    >
                      AAST CONTRACT ADDRESS
                    </p>
                    <p
                      style={{
                        fontSize: "17px",
                        textAlign: "center",
                        alignContent: "center",
                        margin: "0 auto",
                        justifyContent: "center",
                        justifySelf: "center",
                      }}
                    >
                      {" "}
                      <Link
                        to="https://bscscan.com/token/0xb1E998b346DDDacD06f01db50645bE52DafB93db"
                        target="_blank"
                        title="Click to view the contract on BscScan"
                        style={{
                          color: "green",
                          border: "2px #fff solid",
                          borderStyle: "groove",
                          // marginLeft: "-75px",
                          paddingRight: "5px",
                          paddingLeft: "5px",
                        }}
                      >
                        0xb1E998b346DDDacD06f01db50645bE52DafB93db
                      </Link>
                      {/* <i
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        title="click to view Qr Code"
                        className="fa fa-qrcode"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                      ></i> */}
                      <strong>
                        {" "}
                        <p style={{ fontSize: "15px", fontStyle: "italic" }}>
                          click the above address to check on BscScan
                        </p>{" "}
                      </strong>
                    </p>
                    {/* Total AAST left */}
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "20px",
                        color: "blue",
                        marginTop: "70px",
                      }}
                    >
                      {" "}
                      Total Pre-Sale: {totalToken}
                    </p>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "20px",
                        color: "red",
                      }}
                    >
                      {" "}
                      Total Sold: {totalSold}
                    </p>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "20px",
                        color: "green",
                      }}
                    >
                      {" "}
                      Total Remaining: {totalRemaining}
                    </p>
                    <h1 className="display-6">Token Pre-Sale</h1>
                    <p className="text-primary fs-5 mb-5">
                      Token Pre-Sale Ends
                    </p>
                  </div>
                  <div className="row g-3">
                    <div
                      className="col-6 col-md-3 wow fadeIn"
                      data-wow-delay="0.1s"
                    >
                      <div className="bg-white text-center p-3">
                        <h1 className="mb-0" id="cdays">
                          {cdays}
                        </h1>
                        <span className="text-primary fs-5">Days</span>
                      </div>
                    </div>
                    <div
                      className="col-6 col-md-3 wow fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <div className="bg-white text-center p-3">
                        <h1 className="mb-0" id="chours">
                          {chours}
                        </h1>
                        <span className="text-primary fs-5">Hours</span>
                      </div>
                    </div>
                    <div
                      className="col-6 col-md-3 wow fadeIn"
                      data-wow-delay="0.5s"
                    >
                      <div className="bg-white text-center p-3">
                        <h1 className="mb-0" id="cminutes">
                          {cminutes}
                        </h1>
                        <span className="text-primary fs-5">Minutes</span>
                      </div>
                    </div>
                    <div
                      className="col-6 col-md-3 wow fadeIn"
                      data-wow-delay="0.7s"
                    >
                      <div className="bg-white text-center p-3">
                        <h1 className="mb-0" id="cseconds">
                          {cseconds}
                        </h1>
                        <span className="text-primary fs-5">Seconds</span>
                      </div>
                    </div>
                    <div className="col-12 text-center py-4">
                      <Link
                        to="/dashboard"
                        className="btn btn-primary py-3 px-4"
                      >
                        Buy Token
                      </Link>
                    </div>
                    <div className="col-12 text-center">
                      <img
                        className="img-fluid m-1"
                        src={img_payment}
                        alt=""
                        style={{ width: "50px" }}
                      />
                      <img
                        className="img-fluid m-1"
                        src={img_payment_2}
                        alt=""
                        style={{ width: "50px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- The QR Modal --> */}
        <div className="modal" id="myModal" style={{ width: "100%" }}>
          <div className="modal-dialog">
            <div className="modal-content" style={{ width: "370px" }}>
              {/* <!-- Modal Header --> */}
              <div className="modal-header">
                <h5 className="modal-title">Address QR Code</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              {/* <!-- QR Modal body --> */}
              <div className="modal-body" style={{ width: "100%" }}>
                <QRCode
                  style={{
                    width: "100%",
                    height: "250px",
                    justifyContent: "center",
                    justifyItems: "center",
                    textAlign: "center",
                    margin: "auto",
                  }}
                  value="0xb1E998b346DDDacD06f01db50645bE52DafB93db"
                  color="blue"
                />
              </div>

              {/* <!--QR Modal footer --> */}
              <div
                className="modal-footer"
                style={{ justifyContent: "center" }}
              >
                <h5 style={{ fontSize: "13px", textAlign: "center" }}>
                  0xb1E998b346DDDacD06f01db50645bE52DafB93db
                </h5>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        {/* <!-- ======= About Us Section ======= > */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About Us</h2>
            </div>

            <div className="row content">
              <div className="col-lg-6">
                <p>
                  Afriq JM Arbitrage System (AAS) is a pioneering digital
                  currency trading platform with a distinctive vision. Utilizing
                  high-frequency trading and advanced algorithms, we
                  automatically acquire digital currencies at opportune moments,
                  allowing us to sell them strategically for optimal returns.
                  More than just a financial endeavor, AAS is driven by a
                  profound sense of purpose.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i> Central to our
                    mission is the AAS token, a digital asset that embodies our
                    commitment to positive change.
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> Through the AAS
                    token, we channel resources toward feeding the poor,
                    facilitating gadget acquisitions, and supporting various
                    charitable initiatives.
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> By integrating the
                    AAS token into our operations, we create a sustainable model
                    where financial success directly translates into social
                    welfare.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                  At AAS, we believe in harnessing the power of digital
                  currencies to create a positive impact. Our mission extends
                  beyond profit-making; we are dedicated to feeding the less
                  fortunate, empowering communities, and enabling widespread
                  access to essential technology. Through our innovative
                  approach to trading, we not only generate wealth but also
                  contribute significantly to social causes.
                </p>
                <Link to="/dashboard" className="btn-learn-more">
                  Buy Now
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= RoadMap Section ======= --> */}
        <section id="roadmap" className="portfolio">
          {/* <!-- Roadmap Start --> */}
          <div className="container-xxl py-5">
            <div className="container">
              <div
                className="text-center mx-auto wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "500px" }}
              >
                <h1 className="display-6">Roadmap</h1>
                <p className="text-primary fs-5 mb-5">
                  We Translate Your Dream Into Reality
                </p>
              </div>
              <div
                className="owl-carousel roadmap-carousel wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="roadmap-item">
                  <div className="roadmap-point">
                    <span></span>
                  </div>
                  <h5>November 2023</h5>
                  <span>Project Inception and Token Pre-Sale </span>
                </div>
                <div className="roadmap-item">
                  <div className="roadmap-point">
                    <span></span>
                  </div>
                  <h5>December 2023</h5>
                  <span>ECOSYSTEM DEVELOPMENT</span>
                </div>
                <div className="roadmap-item">
                  <div className="roadmap-point">
                    <span></span>
                  </div>
                  <h5>Q1 2024</h5>
                  <span>EXPANSION AND INTEGRATION</span>
                </div>
                <div className="roadmap-item">
                  <div className="roadmap-point">
                    <span></span>
                  </div>
                  <h5>Q2 2024</h5>
                  <span>NETWORK EXPANSION AND MATURATION</span>
                </div>
                <div className="roadmap-item">
                  <div className="roadmap-point">
                    <span></span>
                  </div>
                  <h5>Q3 2024</h5>
                  <span>CONTINUOUS IMPROVEMENT AND ADOPTION</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Roadmap End --> */}

        {/* <!-- ======= Frequently Asked Questions Section ======= --> */}
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <p>
                Most of the frequently asked questions by spartans and others
                have been sumarized and answered in the section below; however
                for any other questions that are not treated here, you can
                always contact us.
              </p>
            </div>

            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What is AAS token (AAST)?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      AAS token (AAST) is the utility token of the AAS
                      ecosystem. It’s the People’s Cryptocurrency.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                  >
                    What’s the mission of AAS Ecosystem?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The AAS Ecosystem is a community-powered ecosystem that
                      aims to give people back their freedom to take control of
                      their finances through seven key utility offerings
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                  >
                    What’s the vision of AAS Ecosystem?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • Empowering Lives through Financial Inclusion, Education,
                      and Community Development <br />• Addressing Hunger,
                      Enhancing Financial Stability, Providing Education,
                      Facilitating Digital Access, Property Acquisition, Gadget
                      Procurement, Means of Payment, E-Learning, Exchange
                      Platform, Farming, Staking, and NFTs.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                  >
                    Who are the target beneficiaries of AAS token?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • Widows, Less Privileged Individuals, Students, and Local
                      Communities
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                  >
                    On which chain is AAS token built?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • BEP20: Network Binance Smart Chain is fast and not
                      expensive in terms of gas fee.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-6"
                    className="collapsed"
                  >
                    What’s the tokenomics of AAS token?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-6"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • Total Supply: 10,000,000,000
                      <br />
                      • Locked Reserve: 2,500,000,000
                      <br />• Circulating Supply: 7,500,000,000
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-7"
                    className="collapsed"
                  >
                    Will there be any presale of the token?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-7"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>Yes, there will be.</p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-8"
                    className="collapsed"
                  >
                    If yes, what are the modalities?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-8"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      25% (2,500,000,000) of the token is earmarked for initial
                      circulation, presales included as follows:
                      <br />
                      • First Presale: 30% (750,000,000) Price: 0.15 USDT
                      <br />
                      Minimum Purchase: 20 USDT (Quantity, 133.333 AAST) <br />{" "}
                      Maximum Purchase: 250,000 USDT (Quantity, 1,666,666.7
                      AAST) <br /> Total sale: 112,500,00
                      <br />
                      • Second Presale: 30% (750,000,000) <br /> Price: 0.2 USDT{" "}
                      <br />
                      Minimum Purchase: 20 USDT (Quantity,
                      <br />
                      100 AAST) Maximum Purchase: 250,000 USDT
                      (Quantity,1,250,000 AAST)
                      <br />
                      Total sale: 150,000,000
                      <br />
                      • Third Presale: 40% (1,000,000,000) <br />
                      Price: 0.25 USDT <br />
                      Minimum Purchase: 20 USDT (Quantity, 80 AAST) <br />
                      Maximum Purchase: 250,000 USDT (Quantity, 1,000,000 AAST){" "}
                      <br />
                      Total sale: 250,000,000,000
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-9"
                    className="collapsed"
                  >
                    What’s the market penetration price of AAST?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-9"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>• 0.3 USDT</p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-10"
                    className="collapsed"
                  >
                    Will there be any token burning in future to reduce supply?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-10"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • YES, a deflationary mechanism of reducing the total
                      supply will be implemented to boost demand and increase
                      the market value of the token.{" "}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-11"
                    className="collapsed"
                  >
                    Does AAS token have use cases?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-11"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p> YES</p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-12"
                    className="collapsed"
                  >
                    If yes, what are the use cases?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-12"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      AAS token will be deployed to seven use cases: <br />
                      • AAS Security Surveillance <br />• AAS University of
                      Technology/Crypto Education Platform <br />
                      • AAS Merchandise Marketplace <br />
                      • AAS Solar Powered Estates <br />
                      • AAS Crypto Exchange <br />
                      • AAS Payment System <br />• AAS Transportation System
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-14"
                    className="collapsed"
                  >
                    Does AAST have a white paper?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-14"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • YES, the white paper is available on the token website
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-15"
                    className="collapsed"
                  >
                    Will there be liquidity for the token after launch?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-15"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • YES, we shall embrace liquidity lock to guarantee
                      stability in the price of the token, increase investors'
                      confidence in the project, and avert market manipulations.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-16"
                    className="collapsed"
                  >
                    Is it safe to buy and hold AAS token?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-16"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • YES! The key to long-term success of a token lies in its
                      usefulness. AAS token is exceptional for two main reasons:{" "}
                      <br />
                      • The token already has a thriving use case even before
                      token launch. While the other interesting use cases are
                      being developed speedily, we already have an AAS
                      merchandise market place in the largest commercial city in
                      Nigeria where the token can be exchanged for goods and
                      services. <br />• Secondly, AAS has a formidable large
                      community backing the project. This will drive the token
                      to the moon superfast.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-17"
                    className="collapsed"
                  >
                    Where can AAS token be purchased?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-17"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • AAS token will be available for transactions on the
                      token website for now. Efforts are in top gear to launch
                      AAS Exchange. The token will also be listed for trading on
                      other reputable crypto exchanges soon.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-18"
                    className="collapsed"
                  >
                    Can investors on the Arbitrage arm of the AAS Ecosystem buy
                    AAS token from their back office with their ROI?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-18"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • The two sites are connected so investors can buy AAST
                      with their available ROI.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-19"
                    className="collapsed"
                  >
                    Will the trading capital of investors in the arbitrage arm
                    of AAS Ecosystem be automatically converted to AAS token?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-19"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • NO! Purchase of AAS token is totally voluntary. No
                      investor will be compelled to buy it. However, those that
                      buy will get unprecedented value for their money.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-20"
                    className="collapsed"
                  >
                    Is there any roadmap for the token project?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-20"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • YES! The roadmap is available on the token website. •
                      The AAS Token roadmap outlines a comprehensive plan for
                      the token and its ecosystem, focusing on utility and
                      real-world applications while maintaining a strong
                      community-driven approach.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-21"
                    className="collapsed"
                  >
                    What are the key utilities of AAS Token?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-21"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • The token can be used for transferring value or making
                      payments within the ecosystem. • The token may grant
                      access to educational content or services within the
                      platform. <br /> • Users may trade this token on
                      exchanges, and it could be used as a trading pair for
                      other cryptocurrencies. <br /> • Token holders may have
                      the ability to participate in the governance of the
                      project, which could involve voting on proposals or making
                      decisions that impact the project's direction. <br /> •
                      Users can lock up their tokens in yield farming to earn
                      rewards, often in the form of more tokens. <br /> • Users
                      can stake the token: this typically involves locking up
                      their tokens to support the AAS network's security and, in
                      return, earning rewards or fees. <br /> • The token may be
                      used to purchase or create NFTs (Non-Fungible Tokens)
                      within the ecosystem.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-22"
                    className="collapsed"
                  >
                    What’s the team behind AAS token?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-22"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The AAS Ecosystem has a dynamic, knowledgeable,
                      transparent, dedicated, experienced, and tech-savvy team
                      led by a reputable crypto Expert – JESAM MICHAEL, the very
                      embodiment of all the sterling qualities possessed by the
                      team.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-23"
                    className="collapsed"
                  >
                    Is AAS token a community Project?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-23"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      •YES! The AAS Ecosystem is backed by a very large
                      community of knowledgeable crypto traders/investors,
                      fondly called SPARTANS by the amiable CEO Jesam Michael.
                      This awesome community was founded on crypto education,
                      transparency, strong brotherly-bond, and team spirit.{" "}
                      <br /> • AAS ecosystem will implement a decentralized
                      governance system where AAS Token holders have a say in
                      the development and decision-making process of the
                      ecosystem.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-24"
                    className="collapsed"
                  >
                    Any partnerships to strengthen the AAST project?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-24"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      • Provisions are made for partnerships and collaboration
                      with local NGOs for feeding programs and financial aids;
                      with Tech Companies for E-Learning platforms, gadget
                      procurement, means of payment integration, exchange
                      development, and NFT marketplace; and Financial
                      Institutions for integration of digital payments and
                      microfinance support. <br /> • The AAS community will
                      continue to host several initiatives of humanitarian
                      assistance in different parts of the world, providing
                      basic supplies, sanitary health and support in public
                      education to communities around the world. <br /> • We
                      shall also enable Web3 as a driver of social
                      transformation by making its education and research
                      accessible to all, and advancing global solutions for
                      global humanitarian impact. <br /> • For partnerships
                      contact us at partnership@afriqjmcoin.com.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <!-- End Frequently Asked Questions Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>
                Embark on a journey through the innovative AAS Token ecosystem,
                where a world of Decentralized Applications awaits your
                exploration. Dive into the heart of Web3 technology, seamlessly
                integrated within the AAS Token network, offering you a vibrant
                and dynamic digital experience. Discover the limitless
                possibilities, where cutting-edge technology meets financial
                empowerment, all powered by AAS Token.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Abuja</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>For customer service:</h4>
                    <p>cs@afriqjmcoin.com</p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>For Information:</h4>
                    <p>hello@afriqjmcoin.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>(+234) 08068380742</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <form
                  action=""
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Your Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email">Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      rows="10"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" onClick={Newsletter.subscribeSend}>
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* main ends */}

      {/* <!-- Start of afriqjmcoin Zendesk Widget script --> */}

      {/* End of afriqjmcoin Zendesk Widget script  */}

      {/* News letter */}
      <Newsletter />
      {/* End of Newsletter */}
      {/* footer session */}
      <Footer />
      {/* end of footer session */}
      {/* preloader  */}
      {/* <div id="preloader"></div> */}
      {/* preloader */}
      {/* buttom to top */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
      {/* button to top ends here */}
    </>
  );
}
