/* global GLightbox */
/* global WOW */
/* global JQuery */
import React from "react";
import logo_image from "../../assets/img/logo.png";
// import "../../assets2/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "../../assets2/css/style.css";
// import "../../assets2/lib/owlcarousel/assets/owl.carousel.min.css";
import $ from "jquery";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import "../../assets2/lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css";
import Footer from "../../footer.jsx";

export default function Payment() {
  const [userName, setUseName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [payAddress, setPayAddress] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [amount, setAmount] = useState("");
  const [exchangeCharge, setExchangeCharge] = useState("");
  const [total, setTotal] = useState("");

  useEffect(() => {
    if (!Constants.getCookies("token")) {
      Constants.redirectPage("/signin");
      return false;
    }
    if (!Constants.getItem("payment")) {
      Constants.redirectPage("/buy-token");
      return false;
    }

    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
    getUserdata();
    payData();
  }, []);

  const payData = async () => {
    let payment = JSON.parse(Constants.getItem("payment"));
    setPayAddress(payment.nowpay_result.pay_address);
    setPaymentId(payment.nowpay_result.payment_id);
    setAmount(payment.amount);
    setExchangeCharge(payment.exchange_charge);
    setTotal(payment.amount_pay);
    checkPaymentData();
  };

  const Logout = async () => {
    Constants.logout();
  };

  const getUserdata = async () => {
    let userdata = JSON.parse(Constants.getItem("userdata"));
    setUseName(userdata.fullname);
    setPhoneNo(userdata.phone);
  };

  // copy to clipboard function
  function copyAddress() {
    /* Get the text field */
    var copyText = document.getElementById("myInput");
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    var popup = document.getElementById("myPopup");
    popup.classList.toggle("show");

    setTimeout(function () {
      popup.classList.remove("show");
    }, 3000); //wait 3 seconds
  }

  const checkPaymentData = async () => {
    let payment = JSON.parse(Constants.getItem("payment"));
    let pay = payment.nowpay_result.payment_id;
    let myInterval = setInterval(async () => {
      let checkpay = await ApiServices.getService(
        "account/check-payment/" + pay
      );
      if (checkpay.status && checkpay.data.action === "success") {
        clearInterval(myInterval);
        alert(
          "Your payment of " + checkpay.data.amount + " USDT has been confirmed"
        );
        Constants.redirectPage("/transaction");
      } else if (checkpay.status && checkpay.data.action === "failed") {
        clearInterval(myInterval);
        alert("Your payment window expired, please try to transfer ontime");
        Constants.redirectPage("/transaction");
      }
    }, 30000);
  };

  return (
    <>
      {/* header starts here */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <a href="#" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </a>
          <div
            style={{
              color: "#fff",
              position: "absolute",
              marginLeft: "100px",
              fontSize: "20px",
            }}
          >
            <p> {userName}</p> <p style={{ marginTop: "-20px" }}>{phoneNo}</p>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li>
                <a href="/dashboard" className="nav-link ">
                  Dashboard
                </a>
              </li>
              <li>
                <a className="nav-link active">Buy Token</a>
              </li>
              <li>
                <a className="nav-link" href="/change-password">
                  Change Password
                </a>
              </li>
              <li>
                <a className="nav-link" href="/transaction">
                  Transactions
                </a>
              </li>
              <li>
                <a href="/referral" className="nav-link">
                  Referrals
                </a>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="https://afriqarbitragesystem.zendesk.com/hc/en-gb"
                  target="_blank"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Help Center
                </Link>
              </li>
              <li>
                <a
                  className="nav-link"
                  onClick={Logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* end of header */}

      {/* <!-- ======= Payment Section ======= --> */}
      <section id="contact" className="contact" style={{ marginTop: "40px" }}>
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Payment</h2>
            <p>Deposit Details</p>
          </div>

          <div className="row">
            <div
              className="col-lg-12 d-flex align-items-stretch"
              style={{
                backgroundColor: "#17a2b8",
                height: "70px",
                marginBottom: "10px",
                width: "100%",
              }}
            ></div>
            <div className="col-lg-6 d-flex align-items-stretch">
              <div
                className="info"
                style={{ alignContent: "center", textAlign: "center" }}
              >
                <h5 style={{ margin: "auto 0", textAlign: "left" }}>Address</h5>
                <QRCode
                  style={{ width: "250px", height: "250px" }}
                  value={payAddress}
                  color="blue"
                />
                <input
                  type="text"
                  readOnly="readonly"
                  className="text-for-link"
                  id="myInput"
                  value={payAddress}
                />
                <span
                  title="Copy Link"
                  className="popup"
                  style={{ cursor: "pointer" }}
                  onClick={copyAddress}
                >
                  &nbsp;<i className="fa fa-copy menu-icon-dot"></i>
                  <span className="popuptext" id="myPopup">
                    Address Copied
                  </span>
                </span>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-stretch">
              <div className="card-body  info text-center">
                <div className="alert alert-warning" style={{ padding: "3px" }}>
                  <i
                    className="fa fa-exclamation-circle"
                    aria-hidden="true"
                  ></i>{" "}
                  Please do not close this window until the payment is
                  confirmed.
                </div>
                <h6>
                  Amount: <strong>{amount} USDT</strong>
                </h6>
                <h6>
                  Charges: <strong>{exchangeCharge} %</strong>
                </h6>
                <h6>
                  Total: <strong>{total} USDT</strong>
                </h6>
                <p style={{ padding: "4px" }}>
                  Send {total} USDT by scanning the QR Code or copy the address
                  showed at your left side.
                </p>
                <center>
                  <div className="loader"></div>
                  <p
                    style={{
                      marginLeft: "-50px",
                    }}
                  >
                    Waiting for your payment
                  </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end of Payment */}

      {/* footer starts */}
      <Footer />
      {/* footer ends */}

      {/* <div id="preloader"></div> */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
