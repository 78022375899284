import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { TableCell, TableRow, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import logo_image from "../../assets/img/logo.png";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Referral() {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Adjust this value based on your requirements
  const [username, setUseName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const [total, setTotal] = useState(0);
  const [TotalAast, setTotalAast] = useState(0);
  const [totalRefAast, setTotalRefAast] = useState(0);
  const [totalRefUsdt, setTotalRefUsdt] = useState(0);

  useEffect(() => {
    // import("../../assets/js/main.js");
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
    if (!Constants.getCookies("token")) {
      Constants.redirectPage("/signin");
      return false;
    }
    getUserdata();
  }, []);

  const Logout = async () => {
    Constants.logout();
  };

  const getUserdata = async () => {
    let userdata = JSON.parse(Constants.getItem("userdata"));
    setUseName(userdata.fullname);
    setPhoneNo(userdata.phone);
    let account = await ApiServices.getService("account/referrals");
    if (account.status) {
      const processedData = account.data.map((list) => ({
        email: list.email,
        phone_no: list.phone,
        date: new Date(Number(list.created_on)).toDateString(),
      }));

      setData(processedData);
    }
    let refer_bonus = await ApiServices.getService("account/referral-bonus");
    if (refer_bonus) {
      const processedDataRef = refer_bonus.data.map((list) => ({
        amountaast: list.aast,
        amountusdt: list.usdt,
        referral: list.fullname,
      }));

      setData2(processedDataRef);
      let totalaast = 0;
      let totalusdt = 0;
      if (refer_bonus) {
        let totalBonus = refer_bonus.data.map((list) => {
          totalaast = parseFloat(list.aast) + parseFloat(totalaast);
          totalusdt = parseFloat(list.usdt) + parseFloat(totalusdt);
          setTotalRefAast("");
          setTotalRefUsdt("");
        });
      }
      setTotalRefAast(totalaast);
      setTotalRefUsdt(totalusdt);
    }
  };
  // ends here
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const columns = [
    { id: "email", label: "Email" },
    { id: "phone_no", label: "Phone No" },
    { id: "date", label: "Date" },
  ];

  const slicedData = data.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );
  // Referral Earnings
  const columns2 = [
    { id: "amountaast", label: "Amount (AAST)" },
    { id: "amountusdt", label: "Amount (USDT)" },
    { id: "referral", label: "Referral" },
  ];

  const slicedData2 = data2.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  return (
    <>
      {/* header starts here */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <a href="#" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </a>
          <div
            style={{
              color: "#fff",
              position: "absolute",
              marginLeft: "100px",
              fontSize: "20px",
            }}
          >
            <p> {username}</p> <p style={{ marginTop: "-20px" }}>{phoneNo}</p>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li>
                <a href="/dashboard" className="nav-link ">
                  Dashboard
                </a>
              </li>
              <li>
                <a href="/buy-token" className="nav-link ">
                  Buy Token
                </a>
              </li>
              <li>
                <a className="nav-link" href="/transaction">
                  Transactions
                </a>
              </li>
              <li>
                <Link to="/referral" className="nav-link active">
                  Referrals
                </Link>
              </li>
              <li>
                <Link to="/change-password" className="nav-link">
                  Change Password
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="https://afriqarbitragesystem.zendesk.com/hc/en-gb"
                  target="_blank"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Help Center
                </Link>
              </li>
              <li>
                <a
                  className="nav-link"
                  onClick={Logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>

      {/* section to buy  token*/}
      <section className="contact">
        <div className="container">
          <div
            className="section-title"
            style={{ marginTop: "40px", paddingBottom: "5px" }}
          >
            <h2>Referrals</h2>
          </div>
          <div className="row" style={{ color: "green" }}>
            <div
              className="col-lg-12 mt-5 php-email-form"
              style={{ width: "100%" }}
            >
              {/* <Tabledata /> */}

              <TableContainer component={Paper}>
                <Table style={{ position: "relative" }}>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#3d4d6a" }}>
                      {columns.map((column) => (
                        <TableCell
                          style={{ color: "white", fontWeight: "bolder" }}
                          key={column.id}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {slicedData.map((row) => (
                      <TableRow key={row.id}>
                        {columns.map((column) => (
                          <TableCell key={`${row.id}-${column.id}`}>
                            {column.id === "status" ? (
                              <Badge
                                color={
                                  row.status === "1"
                                    ? "green"
                                    : row.status === "0"
                                    ? "red"
                                    : "Yellow"
                                }
                              >
                                {row.status}
                              </Badge>
                            ) : (
                              row[column.id]
                            )}
                            {/* {row[column.id]} */}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
              />
            </div>
          </div>
          {/* referral ends here */}
          <div
            className="section-title"
            style={{ marginTop: "40px", paddingBottom: "5px" }}
          >
            <h2>Referral Earnings</h2>
          </div>
          <div className="row" style={{ color: "green" }}>
            <div
              className="col-lg-12 mt-5 php-email-form"
              style={{ width: "100%" }}
            >
              <div style={{ float: "left", fontWeight: "bolder" }}>
                {" "}
                Total Amount {totalRefAast} (AAST)
              </div>
              <div style={{ float: "right", fontWeight: "bolder" }}>
                {" "}
                Total Amount {totalRefUsdt} (USDT)
              </div>
              {/* <Tabledata /> */}

              <TableContainer component={Paper}>
                <Table style={{ position: "relative" }}>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#3d4d6a" }}>
                      {columns2.map((column) => (
                        <TableCell
                          style={{ color: "white", fontWeight: "bolder" }}
                          key={column.id}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {slicedData2.map((row) => (
                      <TableRow key={row.id}>
                        {columns2.map((column) => (
                          <TableCell key={`${row.id}-${column.id}`}>
                            {row[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={data2.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
              />
            </div>
          </div>
        </div>
      </section>
      {/* end of  buy section*/}

      {/* <div style={{ float: "left", fontWeight: "bolder" }}>
        {" "}
        Total Successful Transaction(s) {total} (USDT)
      </div>
      <div style={{ float: "right", fontWeight: "bolder" }}>
        {" "}
        Total AAST Acquired {TotalAast} (AAST)
      </div> */}
    </>
  );
}
