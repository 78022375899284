let Constants = (function () {
  // let baseURL = "http://192.168.0.155:4700/api/v1/aast/";
  let baseURL = "https://api.afriqjmcoin.com/api/v1/aast/";
  let serverURL = "https://api.afriqjmcoin.com";

  let getBaseURL = () => {
    return baseURL; // Or pull this from cookie/localStorage
  };

  let getServerURL = () => {
    return serverURL;
  };

  let getAppDetails = () => {
    return {
      app_name: "Afriq Arbitrage System Token",
      app_second_title: "AAS Token",
      environment: "production-sandbox",
    };
  };

  var numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  let formatMoney = (amount, dec = 2) => {
    return numberWithCommas(parseFloat(amount).toFixed(dec));
  };

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let formatDate1 = (datetime) => {
    const d = new Date(datetime);
    let day = d.getDate();
    let mont = month[d.getMonth()];
    let year = d.getFullYear();
    let short_year = year.toString().substr(-2);
    return day + " " + mont + " " + short_year;
  };

  let formatDate2 = (datetime) => {
    const d = new Date(datetime);
    let day = d.getDate();
    let mont = month[d.getMonth()];
    let year = d.getFullYear();
    return day + " " + mont + " " + year;
  };

  var getCookies = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return false;
  };

  var deleteCookie = (name) => {
    document.cookie =
      name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; Secure";
  };

  var createCookieInHour = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000);
    document.cookie =
      cookieName + " = " + cookieValue + "; expires = " + date.toGMTString();
  };

  var formatPhoneNumber = (phoneNumberString) => {
    var phoneNumberString = phoneNumberString.slice(1);
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + " " + match[2] + " " + match[3];
    }
    return null;
  };

  var logout = () => {
    deleteCookie("token");
    removeItem("userdata");
    redirectPage("/signin");
  };

  var setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
    // Also set this in cookie/localStorage
  };

  var getItem = (key) => {
    return localStorage.getItem(key);
    // Also set this in cookie/localStorage
  };

  var removeItem = (key) => {
    return localStorage.removeItem(key);
    // Also remove this in cookie/localStorage
  };

  var redirectPage = (page) => {
    window.location.href = page;
  };

  var globalExport = () => {
    return { endpoint: "", button_text: "" };
  };

  return {
    getItem,
    setItem,
    removeItem,
    getBaseURL,
    getCookies,
    createCookieInHour,
    deleteCookie,
    numberWithCommas,
    getAppDetails,
    redirectPage,
    logout,
    globalExport,
    formatMoney,
    getServerURL,
    formatDate1,
    formatPhoneNumber,
    formatDate2,
  };
})();

export default Constants;
