import { TableCell, TableRow, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import Tabledata from "./tabledata.jsx";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TransactionData() {
  const [data, setData] = useState([]);
  const [username, setUseName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");

  useEffect(() => {
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
    if (!Constants.getCookies("token")) {
      Constants.redirectPage("/signin");
      return false;
    }
    getUserdata();
  }, []);
  const Logout = async () => {
    Constants.logout();
  };

  const getUserdata = async () => {
    let userdata = JSON.parse(Constants.getItem("userdata"));
    setUseName(userdata.fullname);
    setPhoneNo(userdata.phone);
    let account = await ApiServices.getService("account/payments");
    setData(account.data);
  };
  return (
    <>
      {/* section to buy  token*/}
      <section className="contact">
        <div className="container">
          <div className="section-title" style={{ marginTop: "40px" }}>
            <h2>Transactions</h2>
          </div>
          <div className="row" style={{ color: "green" }}>
            <div style={{ color: "red", textAlign: "center" }}>
              {errorMessage ? errorMessage : successMessage}
            </div>
            <div
              className="col-lg-12 mt-5 php-email-form"
              style={{ width: "100%" }}
            >
              {/* <form
                action=""
                method="post"
                role="form"
                className="php-email-form"
              > */}
              <Tabledata />

              {/* <TableContainer>
                <Table>
                  <TableHead style={{ backgroundColor: "#3d4d6a" }}>
                    <StyledTableRow style={{ backgroundColor: "#3d4d6a" }}>
                      <StyledTableCell
                        style={{ color: "white", backgroundColor: "#3d4d6a" }}
                      >
                        Date
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ color: "white", backgroundColor: "#3d4d6a" }}
                      >
                        Amount(USDT)
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          backgroundColor: "#3d4d6a",
                          color: "white",
                        }}
                      >
                        Amount (AAST)
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: "white",
                          backgroundColor: "#3d4d6a",
                        }}
                      >
                        Rate
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: "white",
                          backgroundColor: "#3d4d6a",
                        }}
                      >
                        Payment Method
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: "white",
                          backgroundColor: "#3d4d6a",
                        }}
                      >
                        Status
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((list) => (
                      <TableRow style={{ color: "white" }} key={list.date}>
                        <StyledTableCell
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          {Constants.formatDate1(list.date)}
                        </StyledTableCell>
                        <StyledTableCell style={{ color: "black" }}>
                          {list.amount}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          {list.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          {list.rate}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          {list.payment_method === 1
                            ? "USD"
                            : "Wallet Transfer"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {list.status === "2"
                            ? "Pending"
                            : list.status === "1"
                            ? "Success"
                            : "Failed"}
                        </StyledTableCell>{" "}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> */}
              {/* {/* </form>/ */}
            </div>
          </div>
        </div>
      </section>
      {/* end of  buy section*/}
    </>
  );
}
