import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import logo_image from "../../assets/img/logo.png";
export default function Tabledata() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Adjust this value based on your requirements
  const [username, setUseName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const [total, setTotal] = useState(0);
  const [TotalAast, setTotalAast] = useState(0);

  useEffect(() => {
    import("../../assets/js/main.js");
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });

    getUserdata();
  }, []);

  const Logout = async () => {
    Constants.logout();
  };

  const getUserdata = async () => {
    let userdata = JSON.parse(Constants.getItem("userdata"));
    setUseName(userdata.fullname);
    setPhoneNo(userdata.phone);
    let account = await ApiServices.getService("account/payments");
    let subtotal = 0;
    let subAast = 0;

    if (account.status) {
      let totalbe = account.data.map((list) => {
        if (list.status === "1") {
          subtotal = parseFloat(list.amount) + parseFloat(subtotal);
          subAast = parseFloat(list.quantity) + parseFloat(subAast);
          setTotal("");
          setTotalAast("");
        }
        setTotal(subtotal);
        setTotalAast(subAast);
      });

      const processedData = account.data.map((list) => ({
        date: `${Constants.formatDate1(list.date)}`,
        payment_method: `${
          list.payment_method === 1 ? "USDT" : "Wallet Transfer"
        }`,
        amount: list.amount,
        quantity: list.quantity,
        rate: list.rate,
        status: `${
          list.status === "2"
            ? "Pending"
            : list.status === "1"
            ? "Success"
            : "Failed"
        }`,
      }));

      setData(processedData);
    }
  };
  // ends here
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const columns = [
    { id: "date", label: "Date" },
    { id: "amount", label: "Amount(USDT)" },
    { id: "quantity", label: "Amount (AAST)" },
    { id: "rate", label: "Rate" },
    { id: "payment_method", label: "Payment_Method" },
    { id: "status", label: "Status" },
  ];

  const slicedData = data.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );
  return (
    <>
      {/* header starts here */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <a href="#" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </a>
          <div
            style={{
              color: "#fff",
              position: "absolute",
              marginLeft: "100px",
              fontSize: "20px",
            }}
          >
            <p> {username}</p> <p style={{ marginTop: "-20px" }}>{phoneNo}</p>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li>
                <a href="/dashboard" className="nav-link ">
                  Dashboard
                </a>
              </li>
              <li>
                <a href="/buy-token" className="nav-link ">
                  Buy Token
                </a>
              </li>
              <li>
                <a className="nav-link active" href="/transaction">
                  Transactions
                </a>
              </li>
              <li>
                <Link to="/referral" className="nav-link">
                  Referrals
                </Link>
              </li>
              <li>
                <Link to="/change-password" className="nav-link">
                  Change Password
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="https://afriqarbitragesystem.zendesk.com/hc/en-gb"
                  target="_blank"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Help Center
                </Link>
              </li>
              <li>
                <a
                  className="nav-link"
                  onClick={Logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      <div style={{ float: "left", fontWeight: "bolder" }}>
        {" "}
        Total Successful Transaction(s) {total} (USDT)
      </div>
      <div style={{ float: "right", fontWeight: "bolder" }}>
        {" "}
        Total AAST Acquired {TotalAast} (AAST)
      </div>

      <TableContainer component={Paper}>
        <Table style={{ position: "relative" }}>
          <TableHead>
            <TableRow style={{ backgroundColor: "#3d4d6a" }}>
              {columns.map((column) => (
                <TableCell
                  style={{ color: "white", fontWeight: "bolder" }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {slicedData.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={`${row.id}-${column.id}`}>
                    {column.id === "status" ? (
                      <Badge
                        color={
                          row.status === "1"
                            ? "green"
                            : row.status === "0"
                            ? "red"
                            : "Yellow"
                        }
                      >
                        {row.status}
                      </Badge>
                    ) : (
                      row[column.id]
                    )}
                    {/* {row[column.id]} */}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </>
  );
}
