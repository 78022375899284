import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import logo_image from "../../assets/img/logo.png";
import Footer from "../../footer.jsx";

export default function Otp() {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");

  const [buttonEnabling, setButtonEnabling] = useState({
    text: "Resend",
    enabling: "",
  });
  const [buttonEnablingContinue, setButtonEnablingContinue] = useState({
    text: "Continue",
    enabling: "",
  });
  useEffect(() => {
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
  }, []);

  // resend otp
  const resendotp = async (e) => {
    setButtonEnabling({
      text: "Wait...",
      enabling: "disabled",
    });

    setErrorMessage("");
    setSucessMessage("");
    // otp data
    let emailData = JSON.parse(Constants.getItem("email"));
    const dataOtp = {
      email: emailData.email,
    };
    let account = await ApiServices.postService("auth/resend", dataOtp);

    if (!account.status) {
      setErrorMessage(account.message);
      setButtonEnabling({
        text: "Resend",
        enabling: "",
      });
      return false;
    }

    setSucessMessage(`Onetime Password resend to ${dataOtp.email}`);
    setButtonEnabling({
      text: "Resend",
      enabling: "",
    });
  };

  // Continue function
  const validateInput = async (e) => {
    setButtonEnablingContinue({
      text: "Waiting...",
      enabling: "disabled",
    });

    e.preventDefault();
    let emailData = JSON.parse(Constants.getItem("email"));

    const data = {
      email: emailData.email,
      activation_code: otp,
    };

    try {
      if (otp === "" || otp === undefined || otp === null) {
        setErrorMessage("Onetime password sent to your email is required!");
        setButtonEnablingContinue({
          text: "Continue",
          enabling: "",
        });
        return false;
      }

      let account = await ApiServices.postService("auth/verify", data);

      if (!account.status) {
        setErrorMessage(account.message);
        setButtonEnablingContinue({
          text: "Continue",
          enabling: "",
        });
        return false;
      }
      setSucessMessage(account.message);
      Constants.removeItem("email");
      setButtonEnablingContinue({
        text: "Continue",
        enabling: "",
      });
      const myTimeout = setTimeout(() => {
        Constants.redirectPage("/signin");
      }, 5000);
    } catch (err) {
      setErrorMessage(err);
      setButtonEnablingContinue({
        text: "Continue",
        enabling: "",
      });
      return false;
    }
  };

  return (
    <>
      {/* header starts */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/otp" className="nav-link active">
                  Otp
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* header ends */}

      {/* main starts */}
      <main id="main">
        <section id="cliens" className="cliens section-bg"></section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginTop: "40px" }}>
              <h2>Complete Sign up</h2>
              <div style={{ color: errorMessage ? "red" : "green" }}>
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
            <div className="row">
              <div
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
                // className="col-lg-8 mt-1 mt-lg-0 d-flex align-items-stretch"
              >
                <form
                  // action=""
                  // method="post"
                  // role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="name">
                        Enter the One Time Password sent to your email. It
                        expires in 10 minutes{" "}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      disabled={buttonEnablingContinue.enabling}
                      onClick={validateInput}
                      type="submit"
                    >
                      {buttonEnablingContinue.text}
                    </button>
                  </div>
                  <p>
                    Didn't get otp?{" "}
                    <a
                      disabled={buttonEnabling.enabling}
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={resendotp}
                    >
                      {buttonEnabling.text}
                    </a>{" "}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* main ends */}

      {/* footer starts */}
      <Footer />
      {/* footer ends */}

      {/* preloader starts */}
      {/* <div id="preloader"></div> */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
