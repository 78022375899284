import React from "react";

export default function Newsletter() {
  const subscribeSend = async (e) => {
    e.preventDefault();
    alert("We are still working");
  };
  return (
    <footer id="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h4>Join Our Newsletter</h4>
              <p>
                {" "}
                Join us on this transformative journey, where every trade
                empowers not only our investors but also the less privileged,
                making the world a better place one transaction at a time.
              </p>
              <form action="#" method="post">
                <input type="email" name="email" />
                <input
                  type="submit"
                  value="Subscribe"
                  onClick={subscribeSend}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
