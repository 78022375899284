import axios from "axios";
import Constants from "./Constants";

let ApiServices = (function () {
  const header = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": Constants.getCookies("token"),
    },
  };

  const postService = async (url, data) => {
    let baseUrl = Constants.getBaseURL();
    let fUrl = baseUrl + url;
    try {
      let response = "";
      if (Constants.getCookies("token")) {
        response = await axios.post(fUrl, data, header);
      } else {
        response = await axios.post(fUrl, data);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return error.response.data;
      } else if (error.response.status === 403) {
        Constants.logout();
        return error.response.data;
      } else {
        let errData = {
          status: false,
          message:
            "Opps! Something went wrong, please try again later. Thank you",
          data: null,
        };
        return errData;
      }
    }
  };
  const getService = async (url) => {
    let baseUrl = Constants.getBaseURL();
    let fUrl = baseUrl + url;
    try {
      let response = "";
      if (Constants.getCookies("token")) {
        response = await axios.get(fUrl, header);
      } else {
        response = await axios.get(fUrl);
      }
      return response.data;
    } catch (error) {
      if (error?.response?.status === 400) {
        return error.response.data;
      } else if (error?.response?.status === 403) {
        Constants.logout();
        return error?.response?.data;
      } else {
        let errData = {
          status: false,
          message:
            "Opps! Something went wrong, please try again later. Thank you",
          data: null,
        };
        return errData;
      }
    }
  };

  const deleteService = async (url) => {
    let baseUrl = Constants.getBaseURL();
    let fUrl = baseUrl + url;
    try {
      let response = "";
      if (Constants.getCookies("token")) {
        response = await axios.delete(fUrl, header);
      } else {
        response = await axios.delete(fUrl);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return error.response.data;
      } else if (error.response.status === 403) {
        Constants.logout();
        return error.response.data;
      } else {
        let errData = {
          status: false,
          message:
            "Opps! Something went wrong, please try again later. Thank you",
          data: null,
        };
        return errData;
      }
    }
  };

  const putService = async (url, data) => {
    let baseUrl = Constants.getBaseURL();
    let fUrl = baseUrl + url;
    try {
      let response = "";
      if (Constants.getCookies("token")) {
        response = await axios.put(fUrl, data, header);
      } else {
        response = await axios.put(fUrl, data);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return error.response.data;
      } else if (error.response.status === 403) {
        Constants.logout();
        return error.response.data;
      } else {
        let errData = {
          status: false,
          message:
            "Opps! Something went wrong, please try again later. Thank you",
          data: null,
        };
        return errData;
      }
    }
  };
  return {
    postService,
    getService,
    deleteService,
    putService,
  };
})();

export default ApiServices;
