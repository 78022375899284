import React from "react";
import { Link } from "react-router-dom";
import privacy from "./privacy/privacy.pdf";
import terms from "./terms/terms.pdf";
export default function Footer() {
  return (
    <>
      {/* footer session */}
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>AAS</h3>
                <p>
                  <strong>Phone:</strong> (+234) 08068380742
                  <br />
                  <strong>Email:</strong> cs@afriqjmcoin.com
                  <br />
                </p>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="/#about">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href={terms} target="_blank">
                      Terms of service
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href={privacy} target="_blank">
                      Privacy policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>
                  Empower Your Ventures with AAS Token: Customized Solutions for
                  Sustainable Growth.
                </p>
                <div className="social-links mt-3">
                  <a
                    href="https://x.com/AfriqArbitrage?t=N0XHcoH5gjAV1hoN6rHz6Q&s=08"
                    className="twitter"
                    target="_blank"
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/afriqjmarbitrage?mibextid=PzaGJu"
                    className="facebook"
                    target="_blank"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="https://instagram.com/afriq_arbitrage?igshid=djlmdHJ6ODYydXl2"
                    className="instagram"
                    target="_blank"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a
                    href="https://t.me/+KRUGqHEAuDg0ZDk0"
                    className="google-plus"
                    target="_blank"
                  >
                    <i className="bx bxl-telegram"></i>
                  </a>
                  <a
                    href="https://youtube.com/@afriqarbitrage?si=v3TN2Z5_DHdQjIDu"
                    className="linkedin"
                    target="_blank"
                  >
                    <i className="bx bxl-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright 2023{" "}
            <strong>
              <span>AAS</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Powered by{" "}
            <a target="_blank" href="https://afriqjmarbitrage.us">
              AAS
            </a>
          </div>
        </div>
      </footer>
      {/* end of footer session */}
    </>
  );
}
