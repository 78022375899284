/* global GLightbox */
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import logo_image from "../../assets/img/logo.png";
import Footer from "../../footer.jsx";

export default function Buytoken() {
  const [display, setDisplay] = useState("block");
  const [displayBuy, setDisplayBuy] = useState("block");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUseName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [amount, setAmount] = useState(0);
  const [payment_method, setPayment_method] = useState(0);
  const [referral, setReferral] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const [buttonEnabling, setButtonEnabling] = useState({
    text: "Buy Token",
    enabling: "",
  });
  const [buttonConnectEnabling, setButtonconnect] = useState({
    text: "Connect",
    enabling: "",
  });
  const [rate, setRate] = useState("");
  const [minCap, setMinCap] = useState("");
  const [maxCap, setMaxCap] = useState("");
  const [usernameDisplay, setUsernameDisplay] = useState("none");
  const [passwordDisplay, setPasswordDisplay] = useState("none");
  const [connectDisplay, setConnetDisplay] = useState("none");
  const [balanceDisplay, setBalanceDisplay] = useState("none");
  const [cost, setCost] = useState(0);
  const [balance, setBalance] = useState(0);
  const [totalToken, setTotalToken] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);

  useEffect(() => {
    if (!Constants.getCookies("token")) {
      Constants.redirectPage("/signin");
      return false;
    }
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
    getUserdata();
    getRate();
    getTokenomics();
  }, [display]);

  const data = {
    amount: amount,
    payment_method: payment_method,
    email: email,
    password: password,
  };

  const Logout = async () => {
    Constants.logout();
  };

  const ConnectAAS = async (e) => {
    e.preventDefault();
    setButtonconnect({
      text: "Waiting...",
      enabling: "disabled",
    });
    if (email === "" || email === undefined || email === null) {
      setErrorMessage("");
      setErrorMessage("Please your AAS email is required");
      setButtonconnect({
        text: "Connect",
        enabling: "",
      });
      window.scrollTo(0, 50);
      return false;
    }
    if (password === "" || password === null || password === undefined) {
      setErrorMessage("Please your AAS Password is required");
      setButtonconnect({
        text: "Connect",
        enabling: "",
      });
      window.scrollTo(0, 50);
      return false;
    }

    const data = {
      email: email,
      password: password,
    };
    let account = await ApiServices.postService("account/connect", data);

    if (!account.status) {
      setErrorMessage(account.message);
      setButtonconnect({
        text: "Connect",
        enabling: "",
      });
      window.scrollTo(0, 50);
      return false;
    }
    setErrorMessage("");
    showConnet(1);
    setBalance(account.data.balance);
    setBalanceDisplay("block");

    setButtonconnect({
      text: "Connect",
      enabling: "",
    });
    return false;
  };

  // show connect with AAS session
  const showConnet = (pm) => {
    if (pm == "" || pm == 1) {
      setUsernameDisplay("none");
      setPasswordDisplay("none");
      setConnetDisplay("none");
    } else if (pm == 2) {
      setUsernameDisplay("block");
      setPasswordDisplay("block");
      setConnetDisplay("block");
    }
  };

  // get Total cost
  const totalCost = () => {
    let actualCost = amount / rate;
    setCost(Constants.formatMoney(actualCost, 8));
  };

  // get Userdata
  const getUserdata = async () => {
    let userdata = JSON.parse(Constants.getItem("userdata"));
    setUseName(userdata.fullname);
    setPhoneNo(userdata.phone);
    setReferral(userdata.referral);
  };
  const getRate = async () => {
    let Tokendetails = await ApiServices.getService("account/rate");
    setRate(Tokendetails.data.rate);
    setMinCap(Constants.numberWithCommas(Tokendetails.data.min_cap));
    setMaxCap(Constants.numberWithCommas(Tokendetails.data.max_cap));
  };

  // get Tokenomics
  const getTokenomics = async () => {
    let Tokendetails = await ApiServices.getService("account/tokenomics");
    if (Tokendetails) {
      setTotalToken(
        Constants.numberWithCommas(Tokendetails.data.total_for_presale)
      );
      setTotalSold(Constants.numberWithCommas(Tokendetails.data.total_sold));
      let remain =
        parseFloat(Tokendetails.data.total_for_presale) -
        parseFloat(Tokendetails.data.total_sold);
      setTotalRemaining(Constants.numberWithCommas(remain));
    }
  };

  var x = setInterval(function () {
    getTokenomics();
  }, 60000);

  // buy coin function
  const buyCoin = async (e) => {
    e.preventDefault();
    setButtonEnabling({
      text: "Wait...",
      enabling: "disabled",
    });
    if (amount === "" || amount === undefined) {
      setErrorMessage("");
      setErrorMessage("Please amount is necessary");
      return errorMessage;
    }
    if (payment_method === "" || payment_method === undefined) {
      setErrorMessage("");
      setErrorMessage("please choose a payment method");
      return errorMessage;
    }

    // Payment method
    {
      let account = await ApiServices.postService("account/buy", data);

      if (!account.status) {
        setErrorMessage(account.message);
        setButtonEnabling({
          text: "Buy Token",
          enabling: "",
        });
        return false;
      }

      if (account.data.redirect) {
        setButtonEnabling({
          text: "Buy Token",
          enabling: "",
        });
        Constants.setItem("payment", account.data);
        Constants.redirectPage("/payment");
        return false;
      }

      setSucessMessage(account.message);
      setButtonEnabling({
        text: "Buy Token",
        enabling: "",
      });
      const myTimeout = setTimeout(() => {
        Constants.redirectPage("/transaction");
      }, 3000);
    }
  };
  return (
    <>
      {/* header starts here */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <a href="#" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </a>
          <div
            style={{
              color: "#fff",
              position: "absolute",
              marginLeft: "100px",
              fontSize: "20px",
            }}
          >
            <p> {username}</p> <p style={{ marginTop: "-20px" }}>{phoneNo}</p>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li>
                <a href="/dashboard" className="nav-link ">
                  Dashboard
                </a>
              </li>
              <li>
                <a className="nav-link active">Buy Token</a>
              </li>
              <li>
                <a className="nav-link" href="/transaction">
                  Transactions
                </a>
              </li>
              <li>
                <a href="/referral" className="nav-link">
                  Referrals
                </a>
              </li>
              <li>
                <a className="nav-link" href="/change-password">
                  Change Password
                </a>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="https://afriqarbitragesystem.zendesk.com/hc/en-gb"
                  target="_blank"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Help Center
                </Link>
              </li>
              <li>
                <a
                  className="nav-link"
                  onClick={Logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* end of header */}

      {/* section to buy  token*/}
      <section
        style={{ marginTop: "40px", display: `${displayBuy}` }}
        id="contact"
        className="contact"
      >
        <div className="container">
          <div className="section-title">
            <h2>Buy Token</h2>
            <div>
              {" "}
              <strong>See your Referral link below</strong>
              <p style={{ fontWeight: "600" }}>{referral}</p>
              {/* Total AAST left */}
              <p style={{ fontWeight: "500", fontSize: "20px", color: "blue" }}>
                {" "}
                Total Pre-Sale: {totalToken}
              </p>
              <p style={{ fontWeight: "500", fontSize: "20px", color: "red" }}>
                {" "}
                Total Sold: {totalSold}
              </p>
              <p
                style={{ fontWeight: "500", fontSize: "20px", color: "green" }}
              >
                {" "}
                Total Remaining: {totalRemaining}
              </p>
              <br />
            </div>
          </div>
          <div className="row" style={{ color: "green" }}>
            <div className="col-4">
              {" "}
              <strong> Rate :</strong> ${rate}
            </div>
            <div
              className="col-4"
              style={{
                textAlign: "center",
                alignContent: "center",
                listStyle: "none",
              }}
            >
              {" "}
              <strong> Min Buying :</strong> ${minCap}
            </div>
            <div
              style={{
                textAlign: "right",
                alignContent: "right",
                listStyle: "none",
              }}
              className="col-4"
            >
              {" "}
              <strong> Max Buying :</strong> ${maxCap}
            </div>
          </div>
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessage ? errorMessage : successMessage}
          </div>
          <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch col-sm-4">
            <form
              action=""
              method="post"
              role="form"
              className="php-email-form"
            >
              <div className="row">
                <div className="form-group border-2 col-md-12">
                  <label htmlFor="name">Amount to Buy (USD)</label>
                  <input
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      totalCost();
                    }}
                    value={amount}
                    type="number"
                    name="tokenquantity"
                    className="form-control"
                    id="name"
                    required
                  />
                  <p>
                    <strong>
                      {" "}
                      <span style={{ marginRight: "5px" }}> You get</span>
                      {cost} AAST
                    </strong>
                  </p>
                </div>

                {/* AAS payment Method */}
                <div className="form-group">
                  <select
                    className="form-select  border-2"
                    style={{ height: "55px" }}
                    name="paymentMethod"
                    border="solid 2px red"
                    value={payment_method}
                    onChange={(e) => {
                      setPayment_method(e.target.value);
                      showConnet(e.target.value);
                    }}
                  >
                    <option defaultValue value="">
                      Select Your payment method
                    </option>
                    <option value="1"> USDT</option>
                    <option value="2">AAS Wallet</option>
                  </select>
                </div>
                <div></div>
                <div
                  className="form-group border-2 col-md-5"
                  style={{ display: `${usernameDisplay}` }}
                >
                  <label htmlFor="email">Email</label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    type="text"
                    name="email"
                    className="form-control"
                    id="email"
                    required
                  />
                </div>
                <div
                  style={{ display: `${passwordDisplay}` }}
                  className="form-group border-2 col-md-5"
                >
                  <label htmlFor="password">Password</label>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    type="password"
                    name="password"
                    className="form-control"
                    id="password"
                    required
                  />
                </div>

                <div
                  className="form-group border-2 col-md-2"
                  style={{
                    display: `${connectDisplay}`,
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{ marginRight: "10px", fontWeight: "bolder" }}
                    htmlFor=""
                  >
                    {" "}
                    Connect to AAS{" "}
                  </label>
                  <button
                    disabled={buttonConnectEnabling.enabling}
                    type="submit"
                    onClick={ConnectAAS}
                  >
                    {buttonConnectEnabling.text}
                  </button>
                </div>
              </div>
              {/* AAS payment Method Ends*/}
              <div
                style={{
                  color: "green",
                  textAlign: "center",
                  fontWeight: "bolder",
                  display: `${balanceDisplay}`,
                }}
              >
                Wallet Connected Successfully ({email}), AAS Balance: {balance}
                {"USDT"}
              </div>
              <br />
              <div className="text-center">
                <button
                  disabled={buttonEnabling.enabling}
                  type="submit"
                  onClick={buyCoin}
                >
                  {buttonEnabling.text}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* end of  buy section*/}

      {/* footer starts */}
      <Footer />
      {/* footer ends */}

      {/* <div id="preloader"></div> */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
