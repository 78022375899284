import $ from "jquery";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import ApiServices from "../../ApiServices";
import Constants from "../../Constants";
import logo_image from "../../assets/img/logo.png";
import Footer from "../../footer";

export default function Signup() {
  // declaring states
  const [name, setName] = useState("");
  const [phoneNo, setPhoneno] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const [buttonEnabling, setButtonEnabling] = useState({
    text: "Create Account",
    enabling: "",
  });
  const recaptchaRef = React.createRef();
  useEffect(() => {
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
    // import("../../assets/js/main.js");
    // import("../../assets/js/main2.js");
    // if (parameterValue) {
    //   setEmail(parameterValue);
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    if (ref !== "" && ref !== undefined && ref !== null) {
      setReferralCode(ref);
    }
  }, []);

  // navigate
  let navigate = useNavigate();

  // state validation
  const validateStateInput = async (e) => {
    setButtonEnabling({
      text: "Wait...",
      enabling: "disabled",
    });
    setErrorMessage("");
    setSucessMessage("");
    e.preventDefault();

    try {
      if (name === undefined || name === null || name === "") {
        setErrorMessage("");
        setErrorMessage(
          "Name is required, kindly provide your name to continue!"
        );
        setButtonEnabling({
          text: "Create Account",
          enabling: "",
        });
        return false;
      }
      if (phoneNo === undefined || phoneNo === "" || phoneNo === null) {
        setErrorMessage("");
        setErrorMessage(
          "Phone number is required!, kindly provide your phone number to continue!"
        );
        setButtonEnabling({
          text: "Create Account",
          enabling: "",
        });
        return false;
      }
      if (email === undefined || email === null || email === "") {
        setErrorMessage("");
        setErrorMessage(
          "Email is required, kindly provide your email to continue!"
        );
        setButtonEnabling({
          text: "Create Account",
          enabling: "",
        });
        return false;
      }
      if (password === null || password === "" || password === undefined) {
        setErrorMessage("");
        setErrorMessage(
          "Password is required, kindly provide your password to continue!"
        );
        setButtonEnabling({
          text: "Create Account",
          enabling: "",
        });
        return false;
      }
      if (
        confirmPassword === "" ||
        confirmPassword === undefined ||
        password === null
      ) {
        setErrorMessage("");
        setErrorMessage(
          "Confirm password is required, kindly provide your confirm password to continue!"
        );
        setButtonEnabling({
          text: "Create Account",
          enabling: "",
        });
        return false;
      }
      if (password != confirmPassword) {
        setErrorMessage("Password mismatch, please re-type");
        setButtonEnabling({
          text: "Create Account",
          enabling: "",
        });
        return false;
      }

      const data = {
        fullname: name,
        email: email,
        phone: phoneNo,
        password: password,
        confirm_password: confirmPassword,
        referral_code: referralCode,
      };
      recaptchaRef.current.execute();
      let account = await ApiServices.postService("auth/create-account", data);

      if (!account.status) {
        setErrorMessage(account.message);
        setButtonEnabling({
          text: "Create Account",
          enabling: "",
        });
        return false;
      }
      let regEmail = {
        email: data.email,
      };
      Constants.setItem("email", regEmail);
      Constants.redirectPage("/otp");
      // call api function
    } catch (err) {
      setErrorMessage(err);
      setButtonEnabling({
        text: "Create Account",
        enabling: "",
      });
      return false;
    }
  };

  // declaring states
  return (
    <>
      {/* header */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/signup" className="nav-link active">
                  Sign up
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* header ends */}

      {/* main section */}
      <main id="main">
        <section id="cliens" className="cliens section-bg"></section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="text-center" style={{ marginTop: "20px" }}>
              <h2>Sign up</h2>
              <div
                style={{
                  color: "red",
                  textAlign: "center",
                  alignContent: "center",
                  padding: "10px 10px",
                }}
              >
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
            <div className="row">
              <div
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
                // className="col-lg-8 mt-1 mt-lg-0 d-flex align-items-stretch"
              >
                <form
                  // action=""
                  // method="post"
                  // role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="phoneNo">Phone number</label>
                      <input
                        type="number"
                        className="form-control"
                        name="phoneNo"
                        id="phone"
                        onChange={(e) => {
                          setPhoneno(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmPassword"
                        id="confirm_password"
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <ReCAPTCHA
                    sitekey="6LfxMG8pAAAAANc0VGlMyC0EZdid9ASQ5jYtvCdc"
                    size="invisible"
                    ref={recaptchaRef}
                    // onChange={onChange}
                  />
                  <div className="text-center">
                    <button
                      disabled={buttonEnabling.enabling}
                      type="submit"
                      onClick={validateStateInput}
                    >
                      {buttonEnabling.text}
                    </button>
                  </div>
                  <p>
                    Already sign up? <Link to="/signin">Sign in</Link>{" "}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* end of main section */}

      {/* footer starts */}
      <Footer />
      {/* footer ends */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
