import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";

import logo_image from "../../assets/img/logo.png";
import Footer from "../../footer.jsx";
export default function Changepassword() {
  // declaration of states
  // declaration of states
  const [display, setDisplay] = useState("none");
  const [userName, setUseName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [current_password, set_Current_password] = useState("");
  const [new_password, set_new_Password] = useState("");
  const [confirm_new_password, setConfirm_new_password] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const [buttonEnabling, setButtonEnabling] = useState({
    text: "Change Password",
    enabling: true,
  });

  useEffect(() => {
    if (!Constants.getCookies("token")) {
      Constants.redirectPage("/signin");
      return false;
    }
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });

    getUserdata();
  }, []);

  const Logout = async () => {
    Constants.logout();
  };

  const getUserdata = async () => {
    let userdata = JSON.parse(Constants.getItem("userdata"));
    setUseName(userdata.fullname);
    setPhoneNo(userdata.phone);
  };

  // let emailData = JSON.parse(Constants.getItem("email"));
  // const dataOtp = {
  //   email: emailData.email,
  // };

  // validation function
  const validateInput = async (e) => {
    setButtonEnabling({
      text: "Wait...",
      enabling: "disabled",
    });
    setErrorMessage("");
    setSucessMessage("");
    e.preventDefault();
    try {
      if (
        current_password === "" ||
        current_password === undefined ||
        current_password === null
      ) {
        setErrorMessage(
          "Current Password is required, kindly provide your password to continue!"
        );
        setButtonEnabling({
          text: "Change Password",
          enabling: "",
        });
        return false;
      }
      if (
        new_password === "" ||
        new_password === undefined ||
        new_password === null
      ) {
        setErrorMessage(
          "New Password is required, kindly provide your password to continue!"
        );
        setButtonEnabling({
          text: "Change Password",
          enabling: "",
        });
        return false;
      }

      if (
        confirm_new_password === "" ||
        confirm_new_password === undefined ||
        confirm_new_password === null
      ) {
        setErrorMessage(
          "Confirm new Password is required, kindly provide your password to continue!"
        );
        setButtonEnabling({
          text: "Change Password",
          enabling: "",
        });
        return false;
      }

      const data = {
        current_password: current_password,
        new_password: new_password,
        confirm_new_password: confirm_new_password,
      };

      let account = await ApiServices.postService(
        "account/change-password",
        data
      );

      if (!account.status) {
        setErrorMessage(account.message);
        setButtonEnabling({
          text: "Change Password",
          enabling: "",
        });
        return false;
      }

      setSucessMessage(account.message);
      Constants.removeItem("userdata");
      const myTimeout = setTimeout(() => {
        Constants.redirectPage("/signin");
      }, 5000);
    } catch (err) {
      setErrorMessage(err.message);
      setButtonEnabling({
        text: "Change Password",
        enabling: "",
      });
      return errorMessage;
    }
  };
  return (
    <>
      {/* header starts */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/dashboard" className="nav-link">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="/buy-token"
                  style={{ cursor: "pointer" }}
                  className="nav-link"
                >
                  Buy Token
                </Link>
              </li>
              <li>
                <Link to="/transaction" className="nav-link">
                  Transactions
                </Link>
              </li>
              <li>
                <Link to="/referral" className="nav-link">
                  Referrals
                </Link>
              </li>
              <li>
                <Link to="/change-password" className="nav-link active">
                  Change Password
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  to="https://afriqarbitragesystem.zendesk.com/hc/en-gb"
                  target="_blank"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "red",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginLeft: "15px",
                  }}
                >
                  Help Center
                </Link>
              </li>
              <li>
                <a
                  className="nav-link"
                  onClick={Logout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* header ends */}

      {/* main starts */}
      <main id="main">
        <section id="cliens" className="cliens section-bg"></section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginTop: "20px" }}>
              <h2>Change Password</h2>
            </div>
            <div
              style={{
                color: errorMessage ? "red" : "green",
                textAlign: "center",
                alignContent: "center",
                padding: "10px 10px",
              }}
            >
              {errorMessage ? errorMessage : successMessage}
            </div>
            <div className="row">
              <div
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
                // className="col-lg-8 mt-1 mt-lg-0 d-flex align-items-stretch"
              >
                <form
                  // action=""
                  // method="post"
                  // role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="name">Current Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={current_password}
                        id="password"
                        onChange={(e) => {
                          set_Current_password(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="New_Password"
                        id="New_Password"
                        value={new_password}
                        onChange={(e) => {
                          set_new_Password(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="Confirm_Password"
                        id="Confirm_New_Password"
                        value={confirm_new_password}
                        onChange={(e) => {
                          setConfirm_new_password(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div className="text-center"></div>
                  <div className="text-center">
                    <button onClick={validateInput} type="submit">
                      {buttonEnabling.text}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* main ends */}

      {/* footer starts */}
      <Footer />
      {/* footer ends */}
      {/* preloader starts */}
      {/* <div id="preloader"></div> */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
