import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Index from "./components";
import Buytoken from "./components/buytokenComponent/buytoken";
import Forgotpassword from "./components/forgotpassword";
import Changepassword from "./components/otp/changepassword";
import Forgotpasswordotp from "./components/otp/forgotpasswordotp";
import Otp from "./components/otp/otp";
import Resetpassword from "./components/otp/resetpassword";
import Payment from "./components/paymentComponent/payment";
import Signin from "./components/signin/signin";
import Signup from "./components/signup/signup";
import Usecase from "./components/usecase";
import Addwallet from "./components/userComponet/addwallet";
import Dashboard from "./components/userComponet/dashboard";
import Referral from "./components/userComponet/referral";
import TransactionData from "./components/userComponet/transactionData";

export default function RouterPage() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/usecase" element={<Usecase />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/forgot-password" element={<Forgotpassword />} />
          <Route path="/forget-password-otp" element={<Forgotpasswordotp />} />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/change-password" element={<Changepassword />} />
          <Route path="/buy-token" element={<Buytoken />} />
          <Route path="/transaction" element={<TransactionData />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/add-wallet" element={<Addwallet />} />

          {/* <Route
            path="/signup"
            element={
              <Auth>
                {" "}
                <Signup />{" "}
              </Auth>
            }
          /> */}
        </Routes>
      </Router>
    </div>
  );
}
