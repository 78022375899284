import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../ApiServices.js";
import Constants from "../Constants.js";
import logo_image from "../assets/img/logo.png";
import Footer from "../footer";

export default function Forgotpassword() {
  // validation function
  useEffect(() => {
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
  }, []);

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const [buttonEnabling, setButtonEnabling] = useState({
    text: "Continue",
    enabling: true,
  });

  // validation function
  const validateInput = async (e) => {
    setButtonEnabling({
      text: "Wait...",
      enabling: "disabled",
    });
    e.preventDefault();
    try {
      if (email === "" || email === undefined || email === null) {
        setErrorMessage(
          "Email is required, kindly provide your email to continue!"
        );
        setButtonEnabling({
          text: "Continue",
          enabling: "",
        });
        return false;
      }

      const data = {
        email: email,
      };

      let account = await ApiServices.postService("auth/forgot-password", data);
      // Checking for Response Status
      if (!account.status) {
        setErrorMessage(account.message);
        setButtonEnabling({
          text: "Continue",
          enabling: "",
        });
        return false;
      }

      //proceed to forgot password otp
      Constants.setItem("email", data);
      Constants.redirectPage("/forget-password-otp");
    } catch (err) {
      setErrorMessage(err);
      setButtonEnabling({
        text: "Continue",
        enabling: "",
      });
      return errorMessage;
    }
  };

  return (
    <>
      {/* header starts */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/forgot-password" className="nav-link active">
                  Forgot Password
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* header ends here */}

      {/* main starts */}
      <main id="main">
        <section id="cliens" className="cliens section-bg"></section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginTop: "20px" }}>
              <h2>Forgot Password</h2>
            </div>
            <div
              style={{
                color: "red",
                textAlign: "center",
                alignContent: "center",
                padding: "10px 10px",
              }}
            >
              {errorMessage ? errorMessage : successMessage}
            </div>
            <div className="row">
              <div
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
                // className="col-lg-8 mt-1 mt-lg-0 d-flex align-items-stretch"
              >
                <form
                  // action=""
                  // method="post"
                  // role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="name">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    {/* <a
                      onClick={() => {
                        console.log("am click");
                      }}
                    >
                      Sign in
                    </a> */}
                  </div>
                  <div className="text-center">
                    <button onClick={validateInput} type="submit">
                      {buttonEnabling.text}
                    </button>
                  </div>
                  <p style={{ float: "right", marginTop: "0px" }}>
                    Ooh! Remember Password?
                    <Link to="/signin"> Sign in</Link>{" "}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* main ends */}

      {/* footer starts */}
      <Footer />
      {/* footer ends */}

      {/* preloader starts */}
      {/* <div id="preloader"></div> */}
      <Link
        to="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </Link>
      {/* header ends */}
    </>
  );
}
