import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo_image from "../assets/img/logo.png";
import Footer from "../footer.jsx";
import Newsletter from "../newsletter.jsx";

export default function Usecase() {
  useEffect(() => {
    import("../assets/js/main.js");
  }, []);
  return (
    <>
      {/* header */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/usecase" className="nav-link active">
                  Usecase
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* header ends */}

      <main id="main" style={{ marginTop: "40px" }}>
        {/* <!-- ======= AAST UNIVERSITY OF TECHNOLOGY/CRYPTO EDUCATION PLATFORM ======= --> */}
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-1 img"
                style={{
                  backgroundImage: 'url("assets/img/school_programmin.png")',
                }}
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                &nbsp;
              </div>

              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-2">
                <div className="content">
                  <h3>
                    AAST UNIVERSITY OF TECHNOLOGY/CRYPTO EDUCATION PLATFORM{" "}
                  </h3>
                  <p>
                    AAST can be used to pay for all expenses at the AAST
                    University of Technology, including tuition fees, textbooks,
                    and living expenses. This can make it easier and more
                    affordable for students to attend the university. <br /> For
                    example, a student from a developing country could use AAST
                    to pay for their tuition fees at the AAST University of
                    Technology. This would allow the student to attend a
                    high-quality university without having to worry about the
                    high cost of tuition.
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-2"
                        className="collapsed"
                      >
                        <span>01</span> Tuition fee
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-2"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to pay for all expenses at the AAST
                          University of Technology, including tuition fees,
                          textbooks, and living expenses. This can make it
                          easier and more affordable for students to attend the
                          university. For example, a student from a developing
                          country could use AAST to pay for their tuition fees
                          at the AAST University of Technology. This would allow
                          the student to attend a high-quality university
                          without having to worry about the high cost of tuition
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <span>02</span> Students can also use AAST to pay for
                        crypto education courses and tutorials.
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-3"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to pay for crypto education courses
                          and tutorials from both traditional educational
                          institutions and new blockchain-based education
                          platforms. This can make it easier and more affordable
                          for people to learn about cryptocurrency and
                          blockchain technology. For example, a student could
                          use AAST to pay for a crypto trading course from a
                          reputable online education platform. This course would
                          teach the student how to trade cryptocurrencies safely
                          and profitably.
                        </p>
                      </div>
                    </li>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <span>03</span> In addition, users can use AAST to
                        invest in crypto education startups and projects.
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-3"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to invest in crypto education
                          startups and projects that are developing new and
                          innovative ways to teach people about cryptocurrency
                          and blockchain technology. This can be a way to
                          support the development of new crypto education
                          solutions and potentially earn high returns on
                          investment. For example, a user could use AAST to
                          invest in a startup that is developing a new mobile
                          app that teaches people about cryptocurrency in a fun
                          and engaging way. This startup could potentially
                          revolutionize the way people learn about
                          cryptocurrency and make it more accessible to
                          everyone.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End AAST UNIVERSITY OF TECHNOLOGY --> */}

        {/* <!-- ======= AAST MERCHANDISE MARKETPLACE ======= --> */}
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch order-2 order-lg-2 img"
                style={{ backgroundImage: 'url("assets/img/merchant.png")' }}
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                &nbsp;
              </div>
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-1 order-lg-1">
                <div className="content">
                  <h3>
                    Users can use AAST to purchase AAST-branded merchandise,
                    such as t-shirts, hats, and mugs.{" "}
                  </h3>
                  <p>
                    AAST can be used to purchase AAST-branded merchandise from
                    the official AAST merchandise store. This merchandise can be
                    a way to show support for the AAST project and community.
                    For example, a user could use AAST to purchase an AAST
                    t-shirt to wear to the AAST annual conference. This would be
                    a way to show their support for the project and meet other
                    members of the AAST community.
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        className="collapse"
                        data-bs-target="#accordion-list-1"
                      >
                        <span>01</span> Users can also use AAST to purchase
                        merchandise from other merchants who accept AAST coin.{" "}
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-1"
                        className="collapse show"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to purchase merchandise from any
                          merchant who accepts AAST coin. This can make it
                          easier and more affordable for people to buy goods and
                          services from merchants who support cryptocurrency.
                          For example, a user could use AAST to purchase a new
                          laptop from an online retailer that accepts AAST coin.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End AAST MERCHANDISE MARKETPLACE --> */}

        {/* <!-- =======AAST SOLAR POWERED ESTATES ======= --> */}
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-1 img"
                style={{
                  backgroundImage: 'url("assets/img/estate_solar.png")',
                }}
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                &nbsp;
              </div>
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-2 ">
                <div className="content">
                  <h3>AAST SOLAR POWERED ESTATES </h3>
                  <p>
                    Users can use AAST to fund the installation of solar panels
                    on their houses, allowing them to generate their own
                    electricity and lessen their reliance on the grid, or to buy
                    homes in a solar-powered estate, guaranteeing comfortable
                    and environmentally responsible living. AAST can also help
                    fund creative entrepreneurs developing cost-effective and
                    efficient solar panel technology, which has the potential to
                    completely transform the solar energy market and make it
                    more widely accessible.
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-1"
                        className="collapsed"
                      >
                        <span>01</span> Users can use AAST to purchase homes and
                        other properties in AAST solar powered estates.
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-1"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to purchase homes and other
                          properties in AAST solar powered estates. These
                          estates will be powered by renewable energy sources,
                          such as solar and wind power. This will make them more
                          environmentally friendly and affordable to live in.
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-2"
                        className="collapsed"
                      >
                        <span>02</span> Users can also use AAST to pay for solar
                        energy services in AAST solar powered estates
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-2"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to pay for solar energy services in
                          AAST solar powered estates. This includes things like
                          the installation of solar panels and the maintenance
                          of solar energy systems.
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <span>03</span> In addition, users can use AAST to
                        invest in AAST solar powered estate startups and
                        projects.
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-3"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to invest in AAST solar powered
                          estate startups and projects that are developing new
                          and innovative ways to build and manage solar powered
                          estates. This can be a way to support the development
                          of new solar powered estate solutions and potentially
                          earn high returns on investment
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End SOLAR POWERED ESTATES --> */}

        {/* <!-- ======= AAST CRYPTO EXCHANGE ======= --> */}
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                // style={{ backgroundImage: 'url("assets/img/why-us.png")' }}
                style={{
                  backgroundImage: 'url("assets/img/crypto_exchange01.png")',
                }}
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                &nbsp;
              </div>
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                <div className="content">
                  <h3>AAST CRYPTO EXCHANGE </h3>
                  <p>
                    In the cryptocurrency realm, users can use AAST for a number
                    of things, such as exchanging AAST coin for other
                    cryptocurrencies on several exchanges, enabling simple and
                    quick transactions, and paying trading fees to lower the
                    cost of cryptocurrency trading. The AAST cryptocurrency
                    exchange startup and projects can also be funded with AAST,
                    which might aid in their growth and possibly result in large
                    profits by improving the exchange's features and raising the
                    value of the AAST coin.
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        className="collapse"
                        data-bs-target="#accordion-list-1"
                      >
                        <span>01</span> Users can use AAST to trade AAST coin
                        for other cryptocurrencies on all crypto exchanges.{" "}
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-1"
                        className="collapse show"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST allows users to exchange their AAST coins for
                          other types of cryptocurrencies on various online
                          platforms called crypto exchanges. These exchanges are
                          like digital marketplaces where you can trade one type
                          of cryptocurrency for another. So, using AAST, you can
                          easily swap your AAST coins for different
                          cryptocurrencies like Bitcoin or Ethereum, just as you
                          would exchange one currency for another when traveling
                          to another country.
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-2"
                        className="collapsed"
                      >
                        <span>02</span> Users can use AAST to invest in the AAST
                        crypto exchange startup and project.
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-2"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to invest in the AAST crypto exchange
                          startup and project. This is a way to support the
                          development of the exchange and potentially earn high
                          returns on investment.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End AAST CRYPTO EXCHANGE --> */}

        {/* <!-- ======= AAST PAYMENT SYSTEM ======= --> */}
        <section id="why-us" className="why-us section-bg">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-1 img"
                style={{
                  backgroundImage: 'url("assets/img/payment_system01.png")',
                }}
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                &nbsp;
              </div>
              <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-2">
                <div className="content">
                  <h3>
                    <strong>AAST PAYMENT SYSTEM</strong>
                  </h3>
                  <p>
                    Users can leverage AAST to make real-world purchases from
                    merchants who accept AAST coins, offering a convenient way
                    to support cryptocurrency adoption. Additionally, AAST
                    enables swift and cost-effective money transfers between
                    individuals, making it an attractive alternative to
                    traditional money transfer services. Moreover, users can
                    invest in AAST payment system startups and projects,
                    fostering the development of innovative AAST payment
                    solutions and potentially yielding substantial returns on
                    investment. This could include supporting startups creating
                    user-friendly mobile wallet apps for seamless AAST coin
                    transactions.
                  </p>
                </div>

                <div className="accordion-list">
                  <ul>
                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-1"
                        className="collapsed"
                      >
                        <span>01</span>Users can use AAST to pay for goods and
                        services from merchants who accept AAST coin.
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-1"
                        className="collapse show"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to pay for goods and services from
                          merchants who accept AAST coin. This is a way to use
                          AAST coin in the real world and support the adoption
                          of cryptocurrency.
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-2"
                        className="collapsed"
                      >
                        <span>02</span> Users can also use AAST to send and
                        receive money from other people
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        id="accordion-list-2"
                        className="collapse"
                        data-bs-parent=".accordion-list"
                      >
                        <p>
                          AAST can be used to send and receive money from other
                          people. This is a fast and affordable way to transfer
                          money.
                        </p>
                      </div>
                    </li>

                    <li>
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <span>03</span> Users can use AAST to invest in AAST
                        payment system startups and projects.
                        <i className="bx bx-chevron-down icon-show"></i>
                        <i className="bx bx-chevron-up icon-close"></i>
                      </a>
                      <div
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-list-3"
                        className="collapsed"
                      >
                        <p>
                          AAST can be used to invest in AAST payment system
                          startups and projects that are developing new and
                          innovative ways to use AAST coin for payments. This is
                          a way to support the development of new AAST payment
                          system solutions and potentially earn high returns on
                          investment.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End AAST PAYMENT SYSTEM --> */}

        {/* <!-- ======= AAST SECURITY SURVEILLANCE ======= --> */}
        <section id="why-us" className="why-us section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
                // style={{ backgroundImage: 'url("assets/img/security01.pg")' }}
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img src="assets/img/skills.png" className="img-fluid" alt="" />
              </div>
              <div
                className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <h3>AAST SECURITY SURVEILLANCE</h3>
                <p className="fst-italic">
                  AAST can be used for many different security-related tasks,
                  such as paying for security surveillance services like CCTV
                  installation and maintenance. By extending its use to include
                  the purchase of security surveillance tools like cameras,
                  sensors, and alarms, AAST provides a practical and affordable
                  way to increase security. AAST also provides a channel for
                  funding security surveillance companies and creative ideas,
                  fostering the advancement of state-of-the-art security
                  solutions and offering significant returns on investment.
                  Essentially, AAST is a flexible, blockchain-based payment and
                  investment platform that enables customers to improve their
                  security and further the security sector.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End AAST SECURITY SURVEILLANCE --> */}
      </main>
      {/* <!-- End #main --> */}

      {/* News letter */}
      <Newsletter />
      {/* End of Newsletter */}
      {/* footer session */}
      <Footer />
      {/* end of footer session */}
      {/* <div id="preloader"></div> */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
