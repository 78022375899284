import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiServices from "../../ApiServices.js";
import Constants from "../../Constants.js";
import logo_image from "../../assets/img/logo.png";
import Footer from "../../footer.jsx";

export default function Signin() {
  useEffect(() => {
    $(".mobile-nav-toggle").on("click", function (e) {
      $("#navbar").toggleClass("navbar-mobile");
      $(this).toggleClass("bi-list bi-x");
    });
  }, []);

  // declaration of states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSucessMessage] = useState("");
  const [buttonEnabling, setButtonEnabling] = useState({
    text: "Sign in",
    enabling: true,
  });

  // validation function
  const validateInput = async (e) => {
    setButtonEnabling({
      text: "Wait...",
      enabling: "disabled",
    });
    e.preventDefault();
    try {
      if (email === "" || email === undefined || email === null) {
        setErrorMessage(
          "Email is required, kindly provide your email to continue!"
        );
        setButtonEnabling({
          text: "Sign in",
          enabling: "",
        });
        return false;
      }
      if (password === "" || password === undefined || password === null) {
        setErrorMessage(
          "Password is required, kindly provide your password to continue!"
        );
        setButtonEnabling({
          text: "Sign in",
          enabling: "",
        });
        return false;
      }

      const data = {
        email: email,
        password: password,
      };

      let account = await ApiServices.postService("auth/login", data);

      if (!account.status) {
        setErrorMessage(account.message);
        setButtonEnabling({
          text: "Sign in",
          enabling: "",
        });
        if (account.data.stage === 0) {
          let regEmail = {
            email: account.data.email,
          };
          Constants.setItem("email", regEmail);
          Constants.redirectPage("/otp");
        }
        return false;
      }

      //proceed to dashboard
      Constants.createCookieInHour("token", account.data.token, 3);
      delete account.data.token;
      Constants.setItem("userdata", account.data);
      Constants.redirectPage("/dashboard");
    } catch (err) {
      setErrorMessage(err);
      setButtonEnabling({
        text: "Sign in",
        enabling: "",
      });
      return errorMessage;
    }
  };

  return (
    <>
      {/* header starts */}
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src={logo_image} alt="" className="img-fluid" />
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/signin" className="nav-link active">
                  Sign in
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}
        </div>
      </header>
      {/* header ends */}

      {/* main starts */}
      <main id="main">
        <section id="cliens" className="cliens section-bg"></section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginTop: "20px" }}>
              <h2>Sign in</h2>
            </div>
            <div
              style={{
                color: "red",
                textAlign: "center",
                alignContent: "center",
                padding: "10px 10px",
              }}
            >
              {errorMessage ? errorMessage : successMessage}
            </div>
            <div className="row">
              <div
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
                // className="col-lg-8 mt-1 mt-lg-0 d-flex align-items-stretch"
              >
                <form className="php-email-form">
                  <div className="row">
                    <div className="form-group">
                      <label htmlFor="name">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-center"></div>
                  <div className="text-center">
                    <button onClick={validateInput} type="submit">
                      {buttonEnabling.text}
                    </button>
                  </div>
                  <p>
                    Don't have account? <Link to="/signup">Sign up</Link>{" "}
                  </p>
                  <p style={{ float: "right", marginTop: "-20px" }}>
                    Forgot password?
                    <Link to="/forgot-password"> Ask for help</Link>{" "}
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* main ends */}

      {/* footer starts */}
      <Footer />
      {/* footer ends */}

      {/* preloader starts */}
      {/* <div id="preloader"></div> */}
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
}
